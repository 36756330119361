jQuery(function($){
    if(window.dipi_scroll_top_vars.use_btt_custom_link){
        $('.et_pb_scroll_top').wrap('<a class="dipi_btt_link"></a>');
        $('.et_pb_scroll_top').off('click');
        $('.dipi_btt_link').attr('href', window.dipi_scroll_top_vars.btt_link);
    }

    if(window.dipi_scroll_top_vars.btt_button_style === 'display_text'){
        if($('.dipi_btt_wrapper').length) return;
		$('.et_pb_scroll_top').addClass('dipi-custom-text');
		$('.et_pb_scroll_top').append(`<div class="dipi_btt_wrapper"><span class="btt_custom_text">${window.dipi_scroll_top_vars.btt_custom_text}</span></div>`);
    } else if (window.dipi_scroll_top_vars.btt_button_style === 'display_text_icon'){
        if($('.dipi_btt_wrapper').length) return;
        $('.et_pb_scroll_top').addClass('dipi-custom-text-icon');
        $('.et_pb_scroll_top').append(`<div class="dipi_btt_wrapper"><span class="btt_custom_text">${window.dipi_scroll_top_vars.btt_custom_text}</span></div>`);
    } else {
        if($('.dipi_btt_wrapper').length) return;
        $('.et_pb_scroll_top').addClass('dipi-custom-icon');
        $('.et_pb_scroll_top').append('<div class="dipi_btt_wrapper"></div>');
    }

    $('span.btt_custom_text').addClass(`dipi-text-${window.dipi_scroll_top_vars.btt_text_placement}`);
    $('.et_pb_scroll_top').addClass(`dipi-btt-${window.dipi_scroll_top_vars.btt_hover_anim}`);
});